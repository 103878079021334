import { debounce } from "lodash"
import { useEffect, useState, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextField,
  Box,
  Button,
  Typography,
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ToggleButtonGroup,
  ToggleButton
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Header, Footer, CustomStepper } from 'swartbox-theme';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PersonIcon from '@mui/icons-material/Person';
import PaymentIcon from '@mui/icons-material/Payment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useParams } from 'react-router-dom';
import { useOrder } from '../components/order/OrderContext';  // Importeer de OrderContext
import api from '../api';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useNavigate } from 'react-router-dom';
import '../styles/PhoneInput.css';
import { OrderPriceDetail } from 'swartbox-order-library';
import KvKAutocomplete from './Test';

const validateKvKNumber = async (kvkNumber) => {
  try {
    const response = await api.get(`/kvk/profile/${kvkNumber}`);
    if (response.status === 200) {
      return response.data; // Return the data if the request is successful
    }
    return null;
  } catch (error) {
    console.error('KvK validation failed:', error);
    return null;
  }
};

const validateAddress = async (postalCode, houseNumber, addition) => {
  if (!postalCode || !houseNumber) return null;

  try {
    const response = await api.post('/address/get-address', {
      postalCode,
      houseNumber,
      addition,
    });
    if (response.status === 200) {
      return response.data; // Return the data if the request is successful
    }
    return null;
  } catch (error) {
    console.error('Address validation failed:', error);
    return null;
  }
};

// Validation schema with Yup
const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .min(2, 'Voornaam moet minimaal 2 letters hebben')
    .required('Voornaam is verplicht'),
  phoneNumber: yup
    .string()
    .test('is-valid', 'Voer een geldig mobiel telefoonnummer in', (value) =>
      value ? isValidPhoneNumber(value) : true
    )
    .required('Telefoonnummer is verplicht'),
  postalCode: yup
    .string()
    .required('Postcode is verplicht')
    .matches(/^[0-9]{4}[A-Z]{2}$/, 'Voer een geldige postcode in het formaat 1234AB in'),
  houseNumber: yup
    .string()
    .required('Huisnummer is verplicht')
    .matches(/^[0-9]/, 'Alleen cijfers zijn toegestaan'),
  street: yup.string(),
  city: yup.string(),
  additions: yup.array().of(yup.string()).default([]),
  houseNumberAddition: yup.string().when('additions', {
    is: (additions) => additions && additions.length > 0,
    then: (schema) => schema.required('Selecteer een huisnummertoevoeging'),
    otherwise: (schema) => schema.nullable(),
  }),
  address: yup.string().when(['postalCode', 'houseNumber'], {
    is: (postalCode, houseNumber) =>
      postalCode && /^[0-9]{4}[A-Z]{2}$/.test(postalCode) && /^[0-9]/.test(houseNumber),
    then: (schema) =>
      schema.test(
        'validate-address',
        'Ongeldig adres. Controleer de postcode en het huisnummer.',
        async function () {
          const { postalCode, houseNumber, houseNumberAddition } = this.parent;
          const { context } = this.options;

          if (
            postalCode === context.yupAddress.current.postalCode &&
            houseNumber === context.yupAddress.current.houseNumber
          ) {
            return context.yupAddress.current.status;
          }

          context.yupAddress.current.postalCode = postalCode;
          context.yupAddress.current.houseNumber = houseNumber;
          context.yupAddress.current.houseNumberAddition = houseNumberAddition;

          const addressData = await validateAddress(postalCode, houseNumber, houseNumberAddition);
          if (addressData) {
            context.yupAddress.current.status = true;
            context.setDataFromAddress(addressData);
            return true;
          }
          context.yupAddress.current.status = false;
          context.setDataFromAddress();
          return false;
        }
      ),
    otherwise: (schema) => 
      schema.test('clear-address-data', function () {
        const { context } = this.options;
        context.setDataFromAddress();
        return true;
      }).nullable()
  }),
  kvkNumber: yup.string().when('$isBusiness', {
    is: true,
    then: (schema) =>
      schema
        .required('KvK-nummer is verplicht voor zakelijke klanten')
        .test('is-valid-format', 'KvK-nummer bestaat uit 8 cijfers', function (value) {
          const { context } = this.options;

          if (!/^\d{8}$/.test(value)) {
            context.setDataFromKvK();
            return false;
          }

          return true;
        })
        .test(
          'kvk-api-validation',
          'Ongeldig KvK-nummer. Controleer het alstublieft opnieuw.',
          async function (value) {
            const { context } = this.options;
            if (!value || !/^\d{8}$/.test(value)) {
              return false;
            }
            if (value === context.yupKvk.current.lastValidatedKvKNumber) {
              return context.yupKvk.current.lastValidationKvkResult;
            }
            context.yupKvk.current.lastValidatedKvKNumber = value;
            const kvkData = await validateKvKNumber(value);
            if (kvkData) {
              context.setDataFromKvK(kvkData);
              context.yupKvk.current.lastValidationKvkResult = true;
              return true;
            }
            context.yupKvk.current.lastValidationKvkResult = false;
            context.setDataFromKvK();
            return false;
          }
        ),
    otherwise: (schema) => schema.nullable(),
  }),
});


const UserDetails = () => {
  const { key } = useParams(); 
  const { order } = useOrder();  // Gebruik de order uit de context
  const navigate = useNavigate();
  const [isPrivate, setIsPrivate] = useState(order?.Prospect?.Type?.Code === 'PRIVATE');
  const [tradingNames, setTradingNames] = useState([]);
    
  const steps = [
      { label: 'Bestelling', icon: <ShoppingCartIcon />, link: `/${key}`},
      { label: 'Gegevens', icon: <PersonIcon /> },
      { label: 'Betalen', icon: <PaymentIcon />},
      { label: 'Verzonden', icon: <CheckCircleIcon /> },
  ];   
    
  const yupKvk = useRef({
    lastValidatedKvKNumber: '',
    lastValidationKvkResult: false
  });
  const yupAddress = useRef({
    postalCode: '',
    houseNumber: '',
    addition: '',
    status: false
  });

  const [additions, setAdditions] = useState([]);

  const setDataFromKvK = (kvkData) => {
    if (kvkData) {
      const visitAddress = kvkData._embedded.hoofdvestiging.adressen.find(
        (addr) => addr.type === 'bezoekadres'
      );
  
      if (visitAddress) {
        setValue('postalCode', visitAddress.postcode || '');
        setValue('houseNumber', visitAddress.huisnummer || '');
        setValue('street', visitAddress.straatnaam || '');
        setValue('city', visitAddress.plaats || '');
      } else {
        // Clear address fields if no visit address is found
        setValue('postalCode', '');
        setValue('houseNumber', '');
        setValue('street', '');
        setValue('city', '');
      }
  
      const tradingNames = kvkData.handelsnamen.map((h) => h.naam);
      setTradingNames(tradingNames);
    } else {
      // Clear address fields and trading names if kvkData is invalid
      setValue('postalCode', '');
      setValue('houseNumber', '');
      setValue('street', '');
      setValue('city', '');
      setTradingNames([]);
    }
  };

  const setDataFromAddress = (addressData) => {
    if(addressData){
      setAdditions(addressData.additions || []);
      setValue('street', addressData.street || '');
      setValue('city', addressData.city || '');
      setValue('additions', addressData.additions || []);
      setValue('houseNumberAddition', '');
    }else{
      setAdditions([]);
      setValue('street', '');
      setValue('city', '');
      setValue('additions', []);
      setValue('houseNumberAddition', '');
    }
  }

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    setValue,
    trigger,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'all',
    context: { isBusiness: !isPrivate, setDataFromKvK, yupKvk, setDataFromAddress, yupAddress },
    defaultValues: {
      firstName: order?.Prospect?.Private?.FirstName || '',
      phoneNumber: order?.Prospect?.Private?.PhoneNumber || '',
      postalCode: order?.Prospect?.Address?.PostalCode || '',
      houseNumber: order?.Prospect?.Address?.Number || '',
      street: order?.Prospect?.Address?.StreetPOBox || '',
      city: order?.Prospect?.Address?.City || '',
      kvkNumber: order?.Prospect?.Company?.ChamberOfCommerce || '',
      additions: [],
      houseNumberAddition: '',
    },
  });

  useEffect(() => {
    if (!isPrivate && order?.Prospect?.Company?.ChamberOfCommerce) {
      trigger('kvkNumber');
    }
  }, [isPrivate, order?.Prospect?.Company?.ChamberOfCommerce, setValue, trigger]);

  const debouncedTrigger = debounce(() => {
    trigger('address');
  }, 500);
  
  const handlePostalCodeChange = (value) => {
    setValue('postalCode', value.toUpperCase().replace(/\s/g, ''));
    debouncedTrigger();
  };

  const handleHouseNumberChange = (value) => {
    setValue('houseNumber', value);
    debouncedTrigger();
  }   

  const onSubmit = async (data) => {
    // Sla de aangepaste gegevens op of stuur ze door naar de volgende stap
    console.log("Submitted data: ", data);

    // Navigeer naar de checkout pagina
    navigate('/checkout');
  };

  const handleToggleChange = (event, newValue) => {
    if (newValue === 'PRIVATE') {
      order.Prospect.Type.Code = 'COMPANY';
    } else {
      order.Prospect.Type.Code = 'PRIVATE';
    }
    setIsPrivate(newValue === 'PRIVATE');
  };

  const handleNext = async () => {
    
  };

  return (
    <Box 
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        pb: { xs: '95px', md: 0 },
        maxWidth: 'xl',  // Gebruik de maxWidth uit het thema
        mx: 'auto', // Centraal uitlijnen
      })}
    >
      <Header />
      <Container
        sx={(theme) => ({
          backgroundColor: '#3789B1',
          color: theme.palette.primary.contrastText,
          padding: 2,
        })}
      >
        <Box
          sx={{
            width: { xs: '100%', md: '83.33%' },
            margin: '0 auto',
          }}
        >
          <CustomStepper steps={steps} activeStep={1} />
        </Box>
      </Container>
      <Container
        sx={(theme) => ({
            pt: 4,
            flex: 1,
            backgroundColor: theme.palette.common.white, // Gebruikt de witkleur van het thema
        })}
        >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid container size={{xs:12, md:8}}>
              <Grid size={12}>
                <Typography variant="h2">Gegevens</Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                 Vul hieronder de benodigde persoonlijke gegevens in. Wij gaan strikt zorgvuldig met je persoonlijke gegevens om, gegevens worden daarom niet verstrekt aan derden.
                </Typography>
  
                <ToggleButtonGroup
                  value={isPrivate ? 'PRIVATE' : 'COMPANY'}
                  exclusive
                  onChange={handleToggleChange}
                  aria-label="huur type"
                  sx={{ mb: 3 }}
                >
                  <ToggleButton value="PRIVATE" aria-label="Prive">
                    Prive
                  </ToggleButton>
                  <ToggleButton value="COMPANY" aria-label="Zakelijk">
                    Zakelijk
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              {!isPrivate && (
                <>
                  <Grid size={12}>
                    <Controller
                      name="kvkNumber"
                      control={control}
                      render={({ field }) => (
                        <KvKAutocomplete
                          {...field}
                          onSelect={(selectedOption) => {
                            setValue('kvkNumber', selectedOption.kvkNummer);
                            trigger('kvkNumber');
                          }}
                        />
                      )}
                    />
                  </Grid>
                  {tradingNames.length > 0 && (
                    <Grid size={12}>
                      <Controller
                        name="tradingName"
                        control={control}
                        defaultValue={tradingNames[0]}
                        render={({ field }) => (
                          <FormControl
                            fullWidth
                            sx={{
                              mb: 2,
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  borderColor: !errors.tradingName && field.value ? '#4caf50' : '', // Apply green border if valid
                                },
                              },
                            }}
                            error={!!errors.tradingName} // This will apply error styling if the field is invalid
                          >
                            <InputLabel>Handelsnaam</InputLabel>
                            <Select
                              {...field}
                              label="Handelsnaam"
                              disabled={tradingNames.length === 1}
                            >
                              {tradingNames.map((name, index) => (
                                <MenuItem key={index} value={name}>
                                  {name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                      />
                    </Grid>
                  )}
                </>
              )}
              <Grid size={{xs:12, md:6}}>
                <Controller
                  name="firstName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Voornaam"
                      error={!!errors.firstName}
                      helperText={errors.firstName ? errors.firstName.message : ''}
                      fullWidth
                      sx={{
                        mb: 2,
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor:
                              field.value && !errors.firstName
                                ? '#4caf50' : '',
                          },
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid size={{xs:12, md:6}} sx={{ mb: 2 }}>
                <Controller
                  name="phoneNumber"
                  control={control}
                  render={({ field }) => (
                    <PhoneInput
                      {...field}
                      defaultCountry="NL"
                      placeholder="Mobielnummer"
                      className={
                        errors.phoneNumber
                          ? 'PhoneInputError'
                          : field.value && !errors.phoneNumber
                            ? 'PhoneInputValid' : ''
                      }
                    />
                  )}
                />
                {errors.phoneNumber && (
                  <Typography
                    variant="caption"
                    color="error"
                    sx={{ ml: '54px' }}
                  >
                    {errors.phoneNumber.message}
                  </Typography>
                )}
              </Grid>
              <Grid size={4}>
                <Controller
                  name="postalCode"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Postcode"
                      onChange={(e) => handlePostalCodeChange(e.target.value)}
                      error={!!errors.postalCode || !!errors.address}  // Check for postalCode or address errors
                      helperText={
                        errors.postalCode ? errors.postalCode.message : errors.address ? errors.address.message : ''
                      }  // Display either postalCode error or address error
                      fullWidth
                      sx={{
                        mb: 2,
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor:
                              field.value && !errors.postalCode && !errors.address
                                ? '#4caf50' : '',
                          },
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid size={4}>
                <Controller
                  name="houseNumber"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Huisnummer"
                      onChange={(e) => handleHouseNumberChange(e.target.value)}
                      error={!!errors.houseNumber || !!errors.address}
                      helperText={
                        errors.houseNumber ? errors.houseNumber.message : ''
                      }
                      fullWidth
                      sx={{
                        mb: 2,
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor:
                              field.value && !errors.houseNumber
                                ? '#4caf50' : '',
                          },
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid size={{xs:12, md:6}}>
                <Controller
                  name="street"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Straat"
                      disabled
                      fullWidth
                      sx={{
                        mb: 2,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid size={{xs:12, md:6}}>
                <Controller
                  name="city"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Plaats"
                      disabled
                      fullWidth
                      sx={{
                        mb: 2,
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid size={{xs:12, md:4}}>
              <OrderPriceDetail ContractLines={order.SwartboxContract.Contract.Lines} OrderLines={order.Lines}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={handleNext}
                  sx={{ mt: 2 }}
                >
                  Doorgaan
                </Button>
              </OrderPriceDetail>
            </Grid>
          </Grid>
        </form>
      </Container>
      <Footer sx={{ display: { xs: 'none', md: 'block' } }} />
    </Box>
  );
}

export default UserDetails;
