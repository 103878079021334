import React, { useState } from 'react';
import {
  Box,
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Header, Footer, CustomStepper } from 'swartbox-theme';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PersonIcon from '@mui/icons-material/Person';
import PaymentIcon from '@mui/icons-material/Payment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useOrder } from '../components/order/OrderContext'; 
import { useNavigate } from 'react-router-dom';
import { OrderStartDate, OrderRentPrivate, OrderExtraUser, OrderPriceDetail } from 'swartbox-order-library';

const steps = [
    { label: 'Bestelling', icon: <ShoppingCartIcon /> },
    { label: 'Gegevens', icon: <PersonIcon /> },
    { label: 'Betalen', icon: <PaymentIcon /> },
    { label: 'Verzonden', icon: <CheckCircleIcon /> },
  ];
  

const Overview = () => {
  const { order } = useOrder();
  const navigate = useNavigate(); 
  const [startDate, setStartDate] = useState(new Date());
  const [isPrivate, setIsPrivate] = useState(order?.Prospect?.Type?.Code === 'PRIVATE');
  const [extraUserCount, setExtraUserCount] = useState(0);

  const togglePrivateStatus = () => {
    setIsPrivate((prev) => !prev);
  };

  const handleNext = async () => {
    const key = order.Key; // Haal de order key op uit de order
  
    if (order.SwartboxContract.Contract.Relation?.Key) {
      // Als er een Relation.Key is, ga naar de checkout pagina
      navigate(`/${key}/checkout`);
    } else {
      // Als er geen Relation.Key is, ga naar de userDetails pagina
      navigate(`/${key}/userDetails`);
    }
  };

  const handleConfirm = (quantity) => {
    setExtraUserCount(quantity);
  };
  
  const extraUser = { key: 'extraUserKey', label: 'Extra gebruiker', price_current: 10 };

  return (
      <Box 
    sx={(theme) => ({
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      maxWidth: 'xl',  // Gebruik de maxWidth uit het thema
      mx: 'auto', // Centraal uitlijnen
    })}
  >
    <Header />
    <Container
      sx={(theme) => ({
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          padding: 2,
      })}
      >
      <Box
          sx={{
          width: { xs: '100%', md: '83.33%' }, // 100% op mobiel (xs), 10 van de 12 kolommen (83.33%) op md+
          margin: '0 auto', // Center de box horizontaal
          }}
      >
          <Typography variant="h1">Opslagruimte bestellen</Typography>
      </Box>
    </Container>
    <Container
      sx={(theme) => ({
        backgroundColor: '#3789B1',
        color: theme.palette.primary.contrastText,
        padding: 2,
      })}
    >
      <Box
        sx={{
          width: { xs: '100%', md: '83.33%' },
          margin: '0 auto',
        }}
      >
        <CustomStepper steps={steps} activeStep={0} />
      </Box>
    </Container>
    <Container
      sx={(theme) => ({
        pt: 4,
        flex: 1,
        backgroundColor: theme.palette.common.white,
        pb: {xs: 4}
      })}
    >          
      <Box
        sx={{
          width: { xs: '100%', md: '83.33%' },
          margin: '0 auto',
        }}
      >
        <Grid container spacing={2}>
          <Grid size={{xs:12, md:9}}>
            <Typography variant="h6">
              Startdatum
            </Typography>
            <OrderStartDate startDate={startDate} updateStartDate={setStartDate} />
            <OrderRentPrivate isPrivate={isPrivate} togglePrivateStatus={togglePrivateStatus} />
            <OrderExtraUser
              extraUser={extraUser}
              extraUserCount={extraUserCount}
              onConfirm={handleConfirm}
            />        
          </Grid>
          <Grid size={{xs:12, md:3}}>
          <OrderPriceDetail ContractLines={order.SwartboxContract.Contract.Lines} OrderLines={order.Lines}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={handleNext}
              sx={{ mt: 2 }}
            >
              Doorgaan
            </Button>
          </OrderPriceDetail>
          </Grid>
        </Grid>        
      </Box>
    </Container>
    <Footer />
  </Box>
  );
}

export default Overview;
