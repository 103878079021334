import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Button, CircularProgress, Container } from '@mui/material';
import { useOrder } from '../components/order/OrderContext'; // Zorg ervoor dat OrderContext correct is opgezet
import { Header, Footer, CustomStepper } from 'swartbox-theme';
import api from '../api'; // Zorg ervoor dat je API-configuratie correct is
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PersonIcon from '@mui/icons-material/Person';
import PaymentIcon from '@mui/icons-material/Payment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const steps = [
  { label: 'Bestelling', icon: <ShoppingCartIcon /> },
  { label: 'Gegevens', icon: <PersonIcon /> },
  { label: 'Betalen', icon: <PaymentIcon /> },
  { label: 'Verzonden', icon: <CheckCircleIcon /> },
];

const PendingPaymentStatus = () => {
  const { key } = useParams(); // Haal de order key op uit de URL
  const { order, fetchOrder } = useOrder(); // Gebruik de order en loading state uit de context
  const [retryLoading, setRetryLoading] = useState(false); 
  const [isPolling, setIsPolling] = useState(true); 
  const orderRef = useRef(order); 
  
  // Zorg ervoor dat orderRef wordt bijgewerkt wanneer order verandert
  useEffect(() => {
    orderRef.current = order;
  }, [order]);

  // Memoize fetchOrder om onnodige renders te voorkomen
  const memoizedFetchOrder = useCallback(async () => {
    await fetchOrder(key); // Haal de order op met de key
  }, [key, fetchOrder]);

  useEffect(() => {
    let intervalId;

    const pollOrderStatus = async () => {
      try {
        await memoizedFetchOrder(); 

        // Controleer de meest recente order status via orderRef
        if (orderRef.current?.WorkflowStatus?.Code !== 'PENDING_PAYMENT') {
          clearInterval(intervalId); 
          setIsPolling(false); // Stop polling als de status verandert
        }
      } catch (error) {
        console.error('Error fetching order:', error);
        clearInterval(intervalId); 
        setIsPolling(false); 
      }
    };

    if (isPolling) {
      intervalId = setInterval(pollOrderStatus, 3000);
    }

    // Cleanup interval
    return () => clearInterval(intervalId);
  }, [memoizedFetchOrder, isPolling]);

  // Functie voor opnieuw proberen
  const handleRetry = async () => {
    setRetryLoading(true);
    try {
      const response = await api.put(`/order/${order.Key}/workflowTransaction/pendingPayment`);

      if (response.data.CheckoutUrl) {
        window.location.href = response.data.CheckoutUrl;  // Redirect naar Mollie voor betaling
      } else {
        alert('Er is een fout opgetreden bij het verwerken van de betaling.');
        setRetryLoading(false);
      }
    } catch (error) {
      console.error('Payment error:', error);
      alert('Er is een fout opgetreden bij het verwerken van de betaling.');
      setRetryLoading(false);
    }
  };

  const renderStatusMessage = () => {
    switch (order?.WorkflowStatus?.Code) {
      case 'PENDING_PAYMENT':
        return (
          <>
            <Typography variant="h4" color="warning">
              Betaling nog in behandeling
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Je betaling is nog in behandeling. Deze pagina zal automatisch worden bijgewerkt zodra de status verandert.
            </Typography>
          </>
        );
      case 'TO_PAY':
        return (
          <>
            <Typography variant="h4" color="error">
              Betaling mislukt
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              De betaling is mislukt. Probeer het opnieuw.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 4 }}
              onClick={handleRetry}  // Voeg de retry-functionaliteit toe aan de knop
              disabled={retryLoading}  // Deactiveer de knop tijdens het laden
            >
              {retryLoading ? <CircularProgress size={24} /> : 'Probeer opnieuw'} {/* Toon loader tijdens retry */}
            </Button>
          </>
        );
      case 'PAID':
        return (
          <>
            <Typography variant="h4" color="primary">
              Betaling succesvol!
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Bedankt voor je betaling. Je bestelling is bevestigd.
            </Typography>
            <Button variant="contained" color="primary" sx={{ mt: 4 }} href="/">
              Terug naar Home
            </Button>
          </>
        );
      default:
        return (
          <>
            <Typography variant="h4" color="error">
              Onbekende status
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Er is een onbekende status ontvangen. Neem contact op met de ondersteuning.
            </Typography>
            <Button variant="contained" color="primary" sx={{ mt: 4 }} href="/">
              Terug naar Home
            </Button>
          </>
        );
    }
  };

  return (
    <Box 
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        maxWidth: 'xl',  // Gebruik de maxWidth uit het thema
        mx: 'auto', // Centraal uitlijnen
      })}
    >
      <Header />
      <Container
        sx={(theme) => ({
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          padding: 2,
        })}
      >
        <Box
          sx={{
            width: { xs: '100%', md: '83.33%' },
            margin: '0 auto',
          }}
        >
          <CustomStepper steps={steps} activeStep={order?.WorkflowStatus?.Code === 'PAID' ? 3 : 2} />
        </Box>
      </Container>
      <Container
        sx={(theme) => ({
          pt: 4,
          flex: 1,
          backgroundColor: theme.palette.common.white,
        })}
      >
        <Box
          sx={{
            width: { xs: '100%', md: '83.33%' },
            margin: '0 auto',
          }}
        >
          {renderStatusMessage()}
        </Box>
      </Container>
      <Footer />
    </Box>
  );
};

export default PendingPaymentStatus;
