import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  Typography,
  Checkbox,
  FormControlLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link,
  CircularProgress,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useNavigate, useParams } from 'react-router-dom';
import { Header, Footer, CustomStepper } from 'swartbox-theme';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PersonIcon from '@mui/icons-material/Person';
import PaymentIcon from '@mui/icons-material/Payment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useOrder } from '../components/order/OrderContext';  // Importeer de OrderContext
import api from '../api';
import { format } from 'date-fns';

const Checkout = () => {
  const { key } = useParams(); 
  const navigate = useNavigate();
  const { order, fetchOrder } = useOrder();  // Gebruik de order uit de context
  const [loading, setLoading] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const steps = [
    { label: 'Bestelling', icon: <ShoppingCartIcon />, link: `/${key}`},
    { label: 'Gegevens', icon: <PersonIcon />, link: `/${key}/userDetails` },
    { label: 'Betalen', icon: <PaymentIcon />},
    { label: 'Verzonden', icon: <CheckCircleIcon /> },
];   

  useEffect(() => {
    // Controleer of de order nog niet is opgehaald, en haal deze op als dat zo is
    if (!order) {
      fetchOrder(key);  // Haal de order op met de order key
    }
  }, [order, key, fetchOrder]);

  const handlePayment = async () => {
    if (!termsAccepted) {
      alert('Please accept the terms and conditions before proceeding.');
      return;
    }

    setLoading(true);

    try {
      const response = await api.put(`/order/${order.Key}/workflowTransaction/pendingPayment`);

      if (response.data.CheckoutUrl) {
        window.location.href = response.data.CheckoutUrl;  // Redirect naar Mollie voor betaling
      } else {
        alert('Er is een fout opgetreden bij het verwerken van de betaling.');
        setLoading(false);
      }
    } catch (error) {
      console.error('Payment error:', error);
      alert('Er is een fout opgetreden bij het verwerken van de betaling.');
      setLoading(false);
    }
  };

  // Controleer of de order beschikbaar is
  if (!order) {
    return <div>Loading...</div>;  // Of een betere laadindicator
  }

  return (
    <Box 
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        maxWidth: 'xl',  // Gebruik de maxWidth uit het thema
        mx: 'auto', // Centraal uitlijnen
      })}
    >
      <Header />
      <Container
        sx={(theme) => ({
          backgroundColor: '#3789B1',
          color: theme.palette.primary.contrastText,
          padding: 2,
        })}
      >
        <Box
          sx={{
            width: { xs: '100%', md: '83.33%' },
            margin: '0 auto',
          }}
        >
          <CustomStepper steps={steps} activeStep={2} />
        </Box>
      </Container>
      <Container
        sx={(theme) => ({
          pt: 4,
          flex: 1,
          backgroundColor: theme.palette.common.white,
        })}
      >
        <Box
          sx={{
            width: { xs: '100%', md: '83.33%' },
            margin: '0 auto',
          }}
        >
          <Typography variant="h4" sx={{ mb: 2 }}>
            Overzicht te betalen
          </Typography>
          <Typography variant="h6">Jouw gegevens</Typography>
          <Grid container spacing={2} sx={{ mb: 3 }}>
            <Grid size={12}>
              <Typography variant="body1">
                <strong>Naam:</strong> {`${order.Prospect.Private.Initials} ${order.Prospect.Private.FirstName} ${order.Prospect.Private.LastName}`}
              </Typography>
              <Typography variant="body1">
                <strong>Email:</strong> {order.Prospect.Private.Email}
              </Typography>
              <Typography variant="body1">
                <strong>Telefoonnummer:</strong> {order.Prospect.Private.PhoneNumber}
              </Typography>
              <Typography variant="body1">
                <strong>Adres:</strong> {`${order.Prospect.Address.StreetPOBox} ${order.Prospect.Address.Number}, ${order.Prospect.Address.PostalCode} ${order.Prospect.Address.City}`}
              </Typography>
              <Link
                component="button"
                variant="body2"
                onClick={() => navigate(`/${order.Key}/userDetails`)}  // Voeg de juiste route toe
                sx={{ mt: 1 }}
              >
                Gegevens aanpassen
              </Link>
            </Grid>
          </Grid>

          {/* Bestellingsinformatie */}
          <Typography variant="h6">Bestelling</Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Omschrijving</TableCell>
                  <TableCell align="right" sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                    Aantal
                  </TableCell>
                  <TableCell align="right" sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                    Prijs
                  </TableCell>
                  <TableCell align="right">Totaal</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order.Lines.map((line, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {line.Description}
                      {line.Period && (
                        <Typography variant="body2" color="textSecondary">
                          Periode: {`${format(new Date(line.Period.StartDate), 'd MMM')} - ${format(new Date(line.Period.EndDate), 'd MMM yyyy')}`}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell align="right" sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                      {line.Quantity}
                    </TableCell>
                    <TableCell align="right" sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                      €{line.UnitPrice.toFixed(2)}
                    </TableCell>
                    <TableCell align="right">€{line.TotalAmount.toFixed(2)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Totaalbedrag */}
          <Box mt={2} sx={{ textAlign: 'right', mb: 2 }}>
            <Typography variant="body1">
              <strong>Totaalbedrag:</strong> €{order.Finance.TotalAmount.toFixed(2)}
            </Typography>
          </Box>

          {/* Voorwaarden */}
          <FormControlLabel
            control={
              <Checkbox
                checked={termsAccepted}
                onChange={(e) => setTermsAccepted(e.target.checked)}
                color="primary"
                disableRipple
              />
            }
            label={
              <Typography variant="body2">
                Ik ga akkoord met de{' '}
                <Link
                  href="https://www.swartbox.nl/wp-content/uploads/2024/08/Algemene-Voorwaarden-Self-Storage.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Algemene voorwaarden
                </Link>
              </Typography>
            }
          />

          {/* Betalen-knop */}
          <Button
            variant="contained"
            color="primary"
            disabled={!termsAccepted || loading} // Deactiveer de knop als de voorwaarden niet zijn geaccepteerd of de betaling bezig is
            onClick={handlePayment}
            sx={{ mt: 2 }}
          >
            {loading ? <CircularProgress size={24} /> : 'Betalen'} {/* Toon een loader tijdens het verwerken */}
          </Button>
        </Box>
      </Container>
      <Footer />
    </Box>
  );
};

export default Checkout;
