import React, { createContext, useContext, useState } from 'react';
import api from '../../api';  // Importeer de API-client

const OrderContext = createContext();

// Custom hook om de context te gebruiken
export const useOrder = () => useContext(OrderContext);

// OrderProvider component dat de context levert aan de kinderen
export const OrderProvider = ({ children }) => {
  const [order, setOrder] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  // Functie om de order op te halen, inclusief GUID-validatie
  const fetchOrder = async (orderKey) => {
    // GUID validatie
    const isValidGuid = (guid) => {
      const guidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
      return guidRegex.test(guid);
    };

    if (!isValidGuid(orderKey)) {
      setError('Invalid GUID format');
      return;
    }

    setLoading(true);  // Zet loading op true voor de API-aanroep

    try {
      const response = await api.get(`/order/${orderKey}`);
      setOrder(response.data);  // Sla de order op in de state
      setError(null);  // Reset eventuele eerdere fouten
    } catch (err) {
      setError(err.response?.status === 404 ? 'Order not found' : 'An unexpected error occurred');
    } finally {
      setLoading(false);  // Zet loading weer op false na de API-aanroep
    }
  };

  return (
    <OrderContext.Provider value={{ order, fetchOrder, error, loading }}>
      {children}
    </OrderContext.Provider>
  );
};
