import KvKAutocomplete from './Test';
import { useForm, Controller } from 'react-hook-form';
import { Button } from '@mui/material';

const UserDetails = () => {
    const { control, handleSubmit, setValue } = useForm();
    
    const onSubmit = (data) => {
      console.log(data);
    };
  
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="kvkCompany"
          control={control}
          render={({ field }) => (
            <KvKAutocomplete
              {...field}
              onChange={(event, value) => setValue('kvkCompany', value)}  // Verbind de geselecteerde waarde met React Hook Form
            />
          )}
        />
        <Button type="submit">Submit</Button>
      </form>
    );
  };
  

  export default UserDetails;
