import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useOrder } from './OrderContext';  // Gebruik de OrderContext
import { ErrorPage, LoadingPage } from 'swartbox-theme';

import Checkout from '../../pages/Checkout';
import RelationDetails from '../../pages/UserDetails';
import ProspectDetails from '../../pages/ProspectDetails';
import Overview from '../../pages/Overview';
import PendingPaymentStatus from '../../pages/PendingPaymentStatus'; 

function OrderHandler() {
  const { key, action } = useParams();  // Haal de key en action uit de URL-parameters
  const { order, fetchOrder, error } = useOrder();
  const [initialLoad, setInitialLoad] = useState(true); 

  useEffect(() => {
    if ((!order || order.Key !== key) && !error) {  // Controleer of de order al bestaat of een andere order is
      fetchOrder(key);
      setInitialLoad(false);
    }
  }, [key, order, fetchOrder, error]);

  if (initialLoad) return <LoadingPage />;  // Laadindicator alleen tonen als we daadwerkelijk aan het laden zijn

  if (error) return <ErrorPage message={error} />;

  // Gebruik een switch-statement om te routeren op basis van de WorkflowStatus.Code
  if(order){
    switch (order.WorkflowStatus.Code) {
      case "QUOTED":
        switch (action) {
          case "userDetails":
            if(!order.SwartboxContract?.Contract?.Relation?.Key){
              return <ProspectDetails />;
            }else{
              return <RelationDetails />;
            } 
          case "checkout":
            return <Checkout />;
          default:
            return <Overview />;
        }
      case "PENDING_PAYMENT":
      case "TO_PAY":
        return <PendingPaymentStatus />;  
      default:
        return <ErrorPage message="Unknown workflow status" />;
    }
  }
}

export default OrderHandler;
