import { useState, useEffect } from 'react';
import { TextField, Autocomplete, CircularProgress, Typography, Box, ListItem } from '@mui/material';
import api from '../api';

const KvKAutocomplete = ({ onSelect }) => {
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchKvKData = async (query) => {
    try {
      const response = await api.get(`/kvk/search?query=${query}`);
      const filteredResults = response.data.resultaten.filter(result => result.type === 'hoofdvestiging');
      return filteredResults;
    } catch (error) {
      console.error('Error fetching KvK data:', error.message);
      return [];
    }
  };

  useEffect(() => {
    if (inputValue.length < 3) {
      setOptions([]);
      return;
    }

    const fetchData = async () => {
      setLoading(true);
      const results = await fetchKvKData(inputValue);
      setOptions(results);
      setLoading(false);
    };

    const delayDebounceFn = setTimeout(() => {
      fetchData();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [inputValue]);

  return (
    <Autocomplete
      freeSolo
      options={options}
      getOptionLabel={(option) => option.naam}
      loading={loading}
      filterOptions={(x) => x}
      onInputChange={(event, newInputValue) => {
        // Alleen API aanroepen als gebruiker typt, niet bij selectie van optie
        if (event && event.type === 'change') {
          setInputValue(newInputValue);
        }
      }}
      onChange={(event, selectedOption) => {
        // Dit wordt aangeroepen wanneer een gebruiker een optie selecteert
        if (selectedOption) {
          onSelect(selectedOption);  // Verwerk de geselecteerde optie
        }
      }}
      renderOption={(props, option) => (
        <ListItem 
          {...props} 
          sx={{
            padding: '8px 16px',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
            borderBottom: '1px solid #e0e0e0'
          }}
        >
          <Box>
            <Typography variant="subtitle2" sx={{ fontSize: '14px', fontWeight: 'bold' }}>
              {option.naam}
            </Typography>
            {option.adres && option.adres.binnenlandsAdres && (
              <Typography variant="body2" sx={{ fontSize: '12px' }} color="textSecondary">
                {option.adres.binnenlandsAdres.straatnaam || 'Straat onbekend'}, {option.adres.binnenlandsAdres.postcode || 'Postcode onbekend'}, {option.adres.binnenlandsAdres.plaats || 'Plaats onbekend'}, NL
              </Typography>
            )}
            <Typography variant="caption" sx={{ fontSize: '11px' }} color="textSecondary">
              KVK-nummer: {option.kvkNummer}
            </Typography>
          </Box>
        </ListItem>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Bedrijfsnaam"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default KvKAutocomplete;
